import React, { useMemo, useRef, useState } from 'react';
import './App.css';
import TinderCard from 'react-tinder-card';

// const db = [
//   {
//     name: 'Monica Hall',
//     url: 'images/RECUADRE.png',
//     rotation: 50,
//   },
//   {
//     name: 'Jared Dunn',
//     url: 'images/RECUADRE.png',
//     rotation: 20,
//   },
//   {
//     name: 'Dinesh Chugtai',
//     url: 'images/RECUADRE.png',
//     rotation: 30,
//   },
//   {
//     name: 'Richard Hendricks',
//     url: 'images/RECUADRE small.gif',
//     rotation: 90,
//   },
//   {
//     name: 'Erlich Bachman',
//     url: 'images/RECUADRE small 2.gif',
//     rotation: 10,
//   },
// ]

const imagesDB = [
  {
    name: 'RECUADRE small 1',
    url: 'images/questions/RECUADRE small 1.gif',
    rotationP: -10,
    rotationN: 10,
  },
  {
    name: 'RECUADRE small 2',
    url: 'images/questions/RECUADRE small 2.gif',
    rotationP: -20,
    rotationN: 20,
  },
  {
    name: 'RECUADRE small 3',
    url: 'images/questions/RECUADRE small 3.png',
    rotationP: 10,
    rotationN: 10,
  },
  {
    name: 'RECUADRE small 4',
    url: 'images/questions/RECUADRE small 4.gif',
    rotationP: 10,
    rotationN: 10,
  },
  {
    name: 'RECUADRE small 5',
    url: 'images/questions/RECUADRE small 5.gif',
    rotationP: 20,
    rotationN: -20,
  },
  {
    name: 'RECUADRE small 6',
    url: 'images/questions/RECUADRE small 6.gif',
    rotationP: -10,
    rotationN: 10,
  },
  {
    name: 'RECUADRE small 7',
    url: 'images/questions/RECUADRE small 7.png',
    rotationP: 10,
    rotationN: -10,
  },
  {
    name: 'RECUADRE small 8',
    url: 'images/questions/RECUADRE small 8.gif',
    rotationP: -20,
    rotationN: 20,
  },
  {
    name: 'RECUADRE small 9',
    url: 'images/questions/RECUADRE small 9.gif',
    rotationP: 10,
    rotationN: -10,
  }, 
  {
    name: 'RECUADRE small 10',
    url: 'images/questions/RECUADRE small 10.gif',
    rotationP: 10,
    rotationN: -10,
  }, 
  {
    name: 'RECUADRE small 11',
    url: 'images/questions/RECUADRE small 11.gif',
    rotationP: -10,
    rotationN: 10,
  }, 
  {
    name: 'RECUADRE small 12',
    url: 'images/questions/RECUADRE small 12.gif',
    rotationP: 10,
    rotationN: 10,
  }, 
  {
    name: 'RECUADRE small 13',
    url: 'images/questions/RECUADRE small 13.gif',
    rotationP: 10,
    rotationN: 10,
  }, 
  {
    name: 'RECUADRE small 14',
    url: 'images/questions/RECUADRE small 14.png',
    rotationP: -15,
    rotationN: 15,
  }, 
  {
    name: 'RECUADRE small 15',
    url: 'images/questions/RECUADRE small 15.gif',
    rotationP: -15,
    rotationN: 15,
  }, 
  {
    name: 'RECUADRE small 16',
    url: 'images/questions/RECUADRE small 16.gif',
    rotationP: 15,
    rotationN: 15,
  }, 
  {
    name: 'RECUADRE small 17',
    url: 'images/questions/RECUADRE small 17.gif',
    rotationP: 15,
    rotationN: 15,
  }, 
  {
    name: 'RECUADRE small 18',
    url: 'images/questions/RECUADRE small 18.gif',
    rotationP: 10,
    rotationN: 10,
  }, 
]

const status = {
  start: 1,
  game: 2,
  end: 3,
}

const swipeDirection = {
  left: 'left',
  right: 'right',
}

const getRandomImages = (arr, num) => {
  const shuffled = arr.sort(() => 0.5 - Math.random());
  return shuffled.slice(0, num);
};

const db = getRandomImages(imagesDB, 10);


function App() {  
  const [currentIndex, setCurrentIndex] = useState(9);
  const [lastDirection, setLastDirection] = useState();
  const [currentStatus, setCurrentStatus] = useState(status.start);
  const [isHovered, setIsHovered] = useState(false);
  const [rotationDegree, setRotationDegree] = useState(0);
  // used for outOfFrame closure
  const currentIndexRef = useRef(currentIndex);

  const childRefs = useMemo(
    () =>
      Array(db.length)
        .fill(0)
        .map((i) => React.createRef()),
    []
  )

  const updateCurrentIndex = (val) => {
    if(val < 0){
      setTimeout(() => {
        setCurrentStatus(status.end);
      }, 2500);
    }
    setCurrentIndex(val)
    currentIndexRef.current = val
  }

  const canGoBack = currentIndex < db.length - 1

  const canSwipe = currentIndex >= 0

  // set last direction and decrease current index
  const swiped = (direction, nameToDelete, index) => {
    setLastDirection(direction)
    updateCurrentIndex(index - 1)

    const dir = direction == swipeDirection.left ? db[currentIndex].rotationN : db[currentIndex].rotationP;

    let newDegree = rotationDegree + dir;

    newDegree = newDegree < -90 ? -90 : newDegree > 90 ? 90 : newDegree;

    console.log(rotationDegree, db[currentIndex], newDegree);
    setRotationDegree(newDegree);
  }

  const outOfFrame = (name, idx) => {
    console.log(`${name} (${idx}) left the screen!`, currentIndexRef.current)
    // handle the case in which go back is pressed before card goes outOfFrame
    currentIndexRef.current >= idx && childRefs[idx].current.restoreCard()
    // TODO: when quickly swipe and restore multiple times the same card,
    // it happens multiple outOfFrame events are queued and the card disappear
    // during latest swipes. Only the last outOfFrame event should be considered valid
  }

  const swipe = async (dir) => {
    if (canSwipe && currentIndex < db.length) {
      await childRefs[currentIndex].current.swipe(dir) // Swipe the card!
    }
  }

  // increase current index and show card
  const goBack = async () => {
    if (!canGoBack) return
    const newIndex = currentIndex + 1
    updateCurrentIndex(newIndex)
    await childRefs[newIndex].current.restoreCard()
  }

  const handleClick = () => {
    window.open('https://www.penguinlibros.com/es/literatura-contemporanea/348905-libro-el-odio-mueve-el-mundo-9788410274181?sqr=el%20odio%20mueve%20e', '_blank');
    // window.location.href = 'https://www.penguinlibros.com/es/literatura-contemporanea/348905-libro-el-odio-mueve-el-mundo-9788410274181?sqr=el%20odio%20mueve%20e';
  };

  return (
    <>
      {
        currentStatus == status.start ?
          <>
            <div className="App">
              <div className="start-header">
                <img src="images/HEMOS VENIDO A JUZGAR.gif" alt="Heading" className="header-image" />
              </div>
              <div className="start-header">
                <img src="images/DEFINITION.png" alt="Heading" className="header-image" />
              </div>
              <div className="start-header">
                <button
                  className="image-button"
                  onMouseEnter={() => setIsHovered(true)} // Set hover state to true
                  onMouseLeave={() => setIsHovered(false)} // Set hover state to false
                  onClick={() => setCurrentStatus(status.game)}
                >
                  <img
                    src={isHovered ? 'images/PLAY_HOVER_1.png' : 'images/PLAY_HOVER_2.png'}
                    alt="Button"
                    className="header-image"
                  />
                </button>
              </div>
            </div>
          </>
        : currentStatus == status.game ?
          <>
            <div className="App">
              <link
                href='https://fonts.googleapis.com/css?family=Damion&display=swap'
                rel='stylesheet'
              />
              <link
                href='https://fonts.googleapis.com/css?family=Alatsi&display=swap'
                rel='stylesheet'
              />
              {/* Header Image */}
              <div className="header">
                <img src="images/HEMOS VENIDO A JUZGAR.gif" alt="Heading" className="header-image" />
              </div>

              {/* Devil, Box, Angel */}
              <div className="characters">
                <div className="devil image-button">
                  <img src="images/DEVIL.gif" className="button-image" alt="Devil" onClick={() => swipe(swipeDirection.left)}/>
                </div>
                <div className="book">
                  <div className="box-placeholder">
                    <div className='cardContainer'>
                      {db.map((character, index) => (
                        <TinderCard
                          ref={childRefs[index]}
                          className='swipe'
                          key={character.name}
                          onSwipe={(dir) => swiped(dir, character.name, index)}
                          onCardLeftScreen={() => outOfFrame(character.name, index)}
                          swipeRequirementType="position"
                          swipeThreshold={150}
                        >
                          <div
                            className='card'  style={{ display: index !== currentIndex ? 'none' : '' }}
                          >
                            <img src={character.url} alt="RECUADRE" />
                          </div>
                        </TinderCard>
                      ))}
                    </div>
                    {/* <img src="images/RECUADRE.png" alt="RECUADRE" /> */}
                    {/* <p className="counter">{`${db.length - currentIndex - 1}/${db.length}`}</p> Counter */}
                    {
                      currentIndex >= 0 && <img className="counter" src={`images/counter/${db.length - currentIndex}_10.png`} alt={`${db.length - currentIndex}/10`}/>
                    }                    
                  </div>
                </div>
                <div className="angel image-button">
                  <img src="images/ANGEL.gif" className="button-image" alt="Angel" onClick={() => swipe(swipeDirection.right)}/>
                </div>
              </div>

              {/* Gauge and Arrow */}
              <div className="gauge-container">
                <img src="images/MESURADOR.png" alt="Gauge" className="gauge" />
                <div className="arrow center" style={{transform: `translate(-50%, -50%) rotate(${rotationDegree}deg)`}}>
                  <img src="images/ARROW.png" alt="Arrow" />
                </div>
                <div className="arrow-message center">
                  <img src="images/MATCH CON MI LIBRO.png" alt="Arrow" />
                </div>
              </div>

              <div className="actions">
                <div className="right image-button">
                  <img className="button-image" src="images/BuyIt.png" alt="Right" onClick={() => {handleClick()}}/>
                </div>
              </div>

              {/* Action Images */}
              <div className="actions">
                <div className="wrong image-button">
                  <img className="button-image" src="images/MAL.png" alt="Wrong" onClick={() => swipe(swipeDirection.left)}/>
                </div>
                <div className="right image-button">
                  <img src="images/BIEN.png" className="button-image" alt="Right" onClick={() => swipe(swipeDirection.right)}/>
                </div>
              </div>
            </div>
          </>
        : 
          <>
            <div className="App">
              <div className="start-header">
                <img src="images/congratulations.gif" alt="Heading" className="header-image" />
              </div>
              <div className="start-header">
                <img src="images/MESSAGE.png" alt="Heading" className="header-image" />
              </div>
              <div className="start-header">
                <button
                  className="image-button"
                  onMouseEnter={() => setIsHovered(true)} // Set hover state to true
                  onMouseLeave={() => setIsHovered(false)} // Set hover state to false
                  onClick={() => handleClick()}
                >
                  <img
                    src={isHovered ? 'images/BUY_HOVER_1.png' : 'images/BUY_HOVER_2.png'}
                    alt="Button"
                    className="button-image"
                  />
                </button>
              </div>
              <div className="start-header">
                <img src="images/cat-buy-more-roy.gif" alt="Heading" className="header-image finish-gif" />
              </div>
            </div>
          </>
      }
    </>
    
  );
}

export default App;
